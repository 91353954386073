
















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private ifEdit: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.$emit("changeJianyi", this.data["医生建议"]);
    return;
    this.data["名称"] = "焦虑自评量表（SAS）报告";
    this.data.score = "65分";
    this.data["评估结果"] = "中度焦虑";
    this.data["介绍"] =
      "SAS是焦虑自评量表，是心理学上的专业名词，是一种焦虑评定的标准，用于测量焦虑状态轻重程度及其在治疗过程中变化情况的心理量表。 SAS适用于具有焦虑症状的成年人，它与SDS一样具有广泛的应用性。";
    this.data.range = ["正常", "轻度焦虑", "中度焦虑", "重度焦虑"];
    this.data["危险因素"] = {
      header: ["指标名称", "您的结果", "正常范围"],
      data: [
        {
          指标名称: "高血压",
          结果: "是",
          正常范围: "",
        },
        {
          指标名称: "糖尿病",
          结果: "是",
          正常范围: "",
        },
        {
          指标名称: "吸烟",
          结果: "是",
          正常范围: "",
        },
        {
          指标名称: "有心脑血管家族史",
          结果: "是",
          正常范围: "",
        },
        {
          指标名称: "服用降压药",
          结果: "是",
          正常范围: "",
        },
        {
          指标名称: "服用降脂药",
          结果: "是",
          正常范围: "",
        },
        {
          指标名称: "BMI",
          结果: "12",
          正常范围: "18.5-23.0",
          result: "偏低",
        },
        {
          指标名称: "腰围",
          结果: "120",
          正常范围: "≤85",
          result: "偏高",
        },
        // {
        //   指标名称: "收缩压",
        //   结果: "160",
        //   正常范围: "90-139",
        //   result: "偏高",
        // },
      ],
    };
  }
  private colors: any = [
    "#08C664",
    "#F2A732",
    "#C861EA",
    "#AE6B07",
    "#117eef",
    "#45F8EF",
    "#F60D0D",
  ];
  private input(e: any) {
    this.$emit("changeJianyi", e);
  }
}
