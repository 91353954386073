















































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private ifEdit: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.$emit("changeJianyi", this.data["医生建议"]);
  }
  private input(e: any) {
    this.$emit("changeJianyi", e);
  }
  private open(url: any) {
    window.open(url);
  }
}
