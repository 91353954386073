







































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private ifEdit: any;
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.$emit("changeJianyi", this.data["医生建议"]);
    this.drawEcharts();
  }
  private header: any = ["体质类型", "您的得分"];
  private colors: any = ["#08C664", "#F2A732", "#AE6B07", "#F60D0D"];
  private optionData: any = {};
  private drawEcharts() {
    let xData: any = [];
    let yData: any = [];
    this.data.score.forEach((ele: any) => {
      xData.push(ele.type);
      yData.push(ele.value);
    });
    this.optionData = {
      color: ["#3387FF"],
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: xData,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
        },
      ],
      series: [
        {
          name: "分数",
          type: "bar",
          barWidth: "60%",
          data: yData,
        },
      ],
    };
  }
  private input(e: any) {
    this.$emit("changeJianyi", e);
  }
}
