






















































import { Component, Vue, Prop } from "vue-property-decorator";
import { getReport, SaveJianyi } from "@/request/custom";
import ReportXinzang from "../components/report/report-xinzang.vue";
import ReportTizhi from "../components/report/report-tizhi.vue";
import ReportCommon from "../components/report/report-common.vue";
@Component({
  components: {
    ReportXinzang,
    ReportTizhi,
    ReportCommon,
  },
})
export default class Name extends Vue {
  private ifEdit: any = false;
  private jianyi: any = "";
  private id: any;
  private data: any = {};
  private changeJianyi(e: any) {
    this.jianyi = e;
    console.log(12122112);
    console.log(e);
  }
  private save() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      content_id: this.id,
      医生建议: this.jianyi,
    };
    SaveJianyi(this, params, loading)
      .then((res: any) => {
        loading.close();
        this.ifEdit = false;
        this.$message.success("保存成功!");
        this.getData();
      })
      .catch(() => {
        loading.close();
      });
  }
  private get pageStyle() {
    //兼容safari浏览器打印时分页错乱
    if (
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      return {
        height: "1271.02px",
      };
    } else {
      return {
        height: "1420px",
      };
    }
  }
  private getData() {
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    const params: any = {
      params: {
        content_id: this.id,
      },
    };
    getReport(this, params, loading).then((res: any) => {
      this.data = res;
      this.data["名称"] = "心脏无忧筛查";
    });
  }
  private open(url: any) {
    window.open(url);
  }
  private mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }
  }
}
